import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import { BodySmall } from '~/components/shared/typography';
import { DISCHARGED_TO_GROUP_TYPE } from '~/constants/filterKeysConstants';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { fetchGroupTypes2 } from '~/ducks/admin/groupTypes2';
import { getDisplayName, getId } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

export type DischargeReasonFormValues = {
  displayName: string;
  dischargedToGroupType: { id: string; displayName: string };
  selectedGroupTypes: { id: string; displayName: string }[];
};

export type DischargeReasonFormProps = {
  isEditing: boolean;
  onCancel: () => void;
};

type Props = DischargeReasonFormProps & FormikProps<DischargeReasonFormValues>;

export default function DischargeReasonForm({ onCancel, isEditing }: Props) {
  const { handleSubmit, isValid, dirty, isSubmitting } = useFormikContext();

  const groupTypeAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    params: { sortBy: 'name asc' },
  });

  const providerAsyncOptions = useAsyncOptions(fetchGroupTypes2, {
    params: { sortBy: 'name asc', type: PROVIDER },
  });

  return (
    <Form>
      <FormHeader title={isEditing ? 'Edit Discharge Disposition' : 'Add Discharge Disposition'} />
      <FormSection>
        <SectionHeader>Disposition Configuration</SectionHeader>

        <InputGroup name='displayName' label='Disposition Label' component={Input} />

        <StyledBody>
          Indicate below if a group type should be linked to this disposition. Linking a group type will display a
          dropdown at time of discharge to select a group
        </StyledBody>

        <InputGroup
          {...groupTypeAsyncOptions}
          name={DISCHARGED_TO_GROUP_TYPE}
          label='Linked Group Type (optional)'
          autoSelectSingleOption={false}
          data-cy={DISCHARGED_TO_GROUP_TYPE}
          getOptionLabel={getDisplayName}
          getOptionValue={getId}
          component={Select}
          isClearable
        />

        <DisplayRulesHeading>Display Rules</DisplayRulesHeading>

        <StyledBody>
          Select below which group types this disposition should be displayed to at time of discharge
        </StyledBody>
        <InputGroup
          {...providerAsyncOptions}
          name='selectedGroupTypes'
          label='Displayed To (select all that apply)'
          placeholder='All Group Types'
          autoSelectSingleOption={false}
          data-cy={DISCHARGED_TO_GROUP_TYPE}
          getOptionLabel={getDisplayName}
          getOptionValue={getId}
          component={MultiSelect}
          labelComponent={MultiSelectLabel}
          selectedCountDefault='All'
          closeMenuOnSelect={false}
        />
      </FormSection>

      <ButtonGroup>
        <Button color='transparent' onClick={onCancel} text='Cancel' />
        <Button
          onClick={handleSubmit}
          disabled={!dirty || !isValid || isSubmitting}
          text={isEditing ? 'Update Disposition' : 'Create Disposition'}
        />
      </ButtonGroup>
    </Form>
  );
}

const StyledBody = styled(BodySmall)`
  margin-bottom: 20px;
`;

const DisplayRulesHeading = styled(SectionHeader)`
  margin-top: 24px;
`;

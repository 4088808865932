/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '~/lib';
import { DischargeReason } from '~/models';
import { adminDischargeReasonsApi } from '~/services/api';

const SLICE_NAME = 'admin/dischargeReasons';

const cancellableFetchDischargeReasons = adminDischargeReasonsApi.fetch.cancellable();

export const fetchDischargeReasons = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = cancellableFetchDischargeReasons(params).then((arg) => arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: DischargeReason,
  } as any
);

export type ShowParams = {
  id: string;
  include: string;
};

export const fetchDischargeReason = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async ({ id, ...params }: ShowParams) => {
    const res = await adminDischargeReasonsApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: DischargeReason,
  } as any
);

export type CreateParams = {
  displayName: string;
  archived?: boolean;
  isReadmission?: boolean;
  order?: number;
  dischargedToGroupTypeId: string;
  groupTypeIds: string[];
};

export const createDischargeReason = createAsyncThunk(`${SLICE_NAME}/create`, async (params: CreateParams) => {
  const res = await adminDischargeReasonsApi.create.invoke(params);

  return res.data;
});

export type UpdateParams = { id: string } & CreateParams;

export const updateDischargeReason = createAsyncThunk(`${SLICE_NAME}/create`, async (params: UpdateParams) => {
  const res = await adminDischargeReasonsApi.update.invoke(params.id, params);

  return res.data;
});

export const archiveDischargeReason = createAsyncThunk(`${SLICE_NAME}/archive`, async (id: string) => {
  const res = await adminDischargeReasonsApi.update.invoke(id, { archived: true });

  return res.data;
});

import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { unwrapResult } from '@reduxjs/toolkit';

import {
  createDischargeReason,
  CreateParams,
  fetchDischargeReason,
  updateDischargeReason,
  UpdateParams,
} from '~/ducks/admin/dischargeReasons';
import { addToast } from '~/ducks/toasts';
import formatValidationErrors from '~/helpers/formatValidationErrors';
import { useThunk } from '~/lib/hooks';

import CircleSpinner from '../shared/CircleSpinner';
import { FormPage } from '../shared/pageLayout';

import DischargeReasonForm, { DischargeReasonFormProps, DischargeReasonFormValues } from './DischargeReasonForm';
import { dischargeReasonFormValidation } from './dischargeReasonFormValidation';

function EditDischargeReason(props: Props) {
  const dischargeReasonId = props.match.params.id;

  const { data: dischargeReason, loaded: dischargeReasonLoaded } = useThunk(fetchDischargeReason, [dischargeReasonId], {
    condition: !!dischargeReasonId,
    params: { id: dischargeReasonId, include: 'dischargedToGroupType,selectedGroupTypes' },
  });

  const navigateToDischargeReasons = () => {
    props.history.push('/discharge-reasons');
  };

  const handleSubmit = (
    values: DischargeReasonFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<DischargeReasonFormValues>
  ) => {
    const request = dischargeReasonId ? props.updateDischargeReason : props.createDischargeReason;
    const valuesForRequest: CreateParams | UpdateParams = {
      id: dischargeReasonId,
      displayName: values.displayName,
      dischargedToGroupTypeId: values.dischargedToGroupType?.id ?? null,
      groupTypeIds: values.selectedGroupTypes.map((groupType) => groupType.id),
    };

    return request(valuesForRequest)
      .then(unwrapResult)
      .catch((error) => {
        const errors = error?.response?.data?.errors;

        if (errors) {
          const formattedErrors = formatValidationErrors(errors, { displayName: 'Disposition Label' });

          Object.keys(formattedErrors).forEach((err) => {
            setFieldError(err, formattedErrors[err]);
          });
        } else {
          props.addToast({
            text: `There was an error ${dischargeReasonId ? 'updating' : 'creating'} the discharge disposition. Please try again.`,
          });
        }
        throw error;
      })
      .then(navigateToDischargeReasons)
      .then(() => props.addToast({ text: `Disposition successfully ${dischargeReasonId ? 'updated' : 'added'}!` }))
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(() => {
    return {
      enableReinitialize: true,
      handleSubmit,
      validationSchema: dischargeReasonFormValidation,
      mapPropsToValues: () => ({ ...dischargeReason }),
    };
  }, [dischargeReason]);

  const FormikDischargeReasonForm = useMemo(
    () => withFormik<DischargeReasonFormProps, DischargeReasonFormValues>(formikOptions)(DischargeReasonForm),
    [formikOptions]
  );

  if (!!dischargeReasonId && !dischargeReasonLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikDischargeReasonForm onCancel={navigateToDischargeReasons} isEditing={!!dischargeReasonId} />
    </FormPage>
  );
}

EditDischargeReason.propTypes = {
  addToast: PropTypes.func.isRequired,
  createDischargeReason: PropTypes.func.isRequired,
  updateDischargeReason: PropTypes.func.isRequired,
};

type RouteProps = RouteComponentProps<{ id?: string }>;
type Props = InferProps<typeof EditDischargeReason.propTypes> & RouteProps;

const mapDispatchToProps = {
  addToast,
  createDischargeReason,
  updateDischargeReason,
};

export default connect(null, mapDispatchToProps)(EditDischargeReason);

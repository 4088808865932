import GroupType, { GroupTypeOptions } from '~/models/GroupType';

export interface DischargeReasonOptions {
  id: string;
  displayName: string;
  isReadmission: boolean;
  archived: boolean;
  dischargedToGroupTypeId: string;
  dischargedToGroupType: Partial<GroupTypeOptions> | null;
  groupTypeDisplayNames?: string;
  groupTypes?: Partial<GroupTypeOptions>[];
  selectedGroupTypes?: Partial<GroupTypeOptions>[];
}

function getDefaults(): DischargeReasonOptions {
  return {
    id: '',
    displayName: '',
    isReadmission: false,
    archived: false,
    dischargedToGroupTypeId: '',
    dischargedToGroupType: null,
    groupTypeDisplayNames: undefined,
  };
}

export default class DischargeReason {
  id: string;
  displayName: string;
  isReadmission: boolean;
  archived: boolean;
  dischargedToGroupTypeId: string;
  dischargedToGroupType: GroupType | null;
  groupTypeDisplayNames?: string;
  groupTypes?: GroupType[];
  selectedGroupTypes?: GroupType[];

  constructor(options: Partial<DischargeReasonOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.displayName = opts.displayName;
    this.isReadmission = opts.isReadmission;
    this.archived = opts.archived;
    this.dischargedToGroupTypeId = opts.dischargedToGroupTypeId;

    // included joins
    this.dischargedToGroupType = opts.dischargedToGroupType ? new GroupType(opts.dischargedToGroupType) : null;
    this.groupTypeDisplayNames = opts.groupTypeDisplayNames;
    this.groupTypes = opts.groupTypes ? opts.groupTypes.map((groupType) => new GroupType(groupType)) : undefined;
    this.selectedGroupTypes = opts.selectedGroupTypes
      ? opts.selectedGroupTypes.map((groupType) => new GroupType(groupType))
      : undefined;
  }
}
